import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isArray } from 'lodash';
import { FaEdit } from 'react-icons/fa';
import { MdOutlineDelete, MdInfoOutline } from 'react-icons/md';
import { getAllData } from '../../../redux/actions/api/GetAllData';
import { deleteData } from '../../../redux/actions/api/DeleteData';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import Table from '../../../components/table/Table';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import Loading from '../../../components/loading/Loading';

export default function SubActivity() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { accessToken } = useSelector((state) => state.auth);
  const {
    data: activities,
    loading,
    error,
  } = useSelector((state) => state.api);
  const [subServiceId, setSubServiceId] = useState();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const title_en = location?.state;
  // ----------------------------------------------------------------------------------->
  const columns = React.useMemo(
    () => [
      {
        Header: 'Image',
        accessor: 'image',
        disableFilters: true,
        Cell: ({ row }) => (
          <img
            width='40'
            height='40'
            className='rounded-circle border'
            src={row.values.image[0]}
            alt='Images Activity'
          />
        ),
      },
      {
        Header: 'Description En',
        disableFilters: true,
        accessor: 'description_en',
      },
      {
        Header: 'Description Ar',
        disableFilters: true,
        accessor: 'description_ar',
      },
      {
        Header: 'Created At',
        disableFilters: true,
        accessor: 'created_at',
        Cell: ({ row }) => (
          <time>{moment(row.values.created_at).format('LLL')}</time>
        ),
      },
      {
        Header: 'Updated At',
        disableFilters: true,
        accessor: 'updated_at',
        Cell: ({ row }) => (
          <time>{moment(row.values.updated_at).format('LLL')}</time>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        disableFilters: true,
        Cell: ({ row }) => (
          <>
            <button className='btn btn-icon btn-rounded btn-primary mr-1'>
              <FaEdit
                size='1rem'
                onClick={() =>
                  navigate(
                    `/activities/${id}/sub-activities/${row.values.id}/edit`
                  )
                }
              />
            </button>
            <button className='btn btn-icon btn-rounded btn-danger mr-1'>
              <MdOutlineDelete
                size='1rem'
                onClick={() => {
                  setSubServiceId(row.values.id);
                  setShowModalConfirm(true);
                }}
              />
            </button>
            <button className='btn btn-icon btn-rounded btn-info'>
              <MdInfoOutline
                size='1rem'
                onClick={() =>
                  navigate(
                    `/activities/${id}/sub-activities/${row.values.id}/details`,
                    {
                      state: row.original,
                    }
                  )
                }
              />
            </button>
          </>
        ),
      },
    ],
    [id, navigate]
  );
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    dispatch(getAllData(`services/${id}/sub-services`, accessToken));
  }, [accessToken, dispatch, id, isShowLoading]);
  // ----------------------------------------------------------------------------------->
  const data = useMemo(() => isArray(activities) && activities, [activities]);
  return (
    <>
      <ModalConfirm
        delete={() =>
          dispatch(
            deleteData(
              `/sub-services/${subServiceId}`,
              accessToken,
              setShowModalConfirm,
              setIsShowLoading,
              isShowLoading
            )
          )
        }
        showModalConfirmTry={setShowModalConfirm}
        toggleModal={showModalConfirm}
      />
      <Breadcrumb
        url={`/activities/${id}/sub-activities/new`}
        title={`All sub activities ${title_en && title_en}`}
        textActive='Sub activities'
        items={[{ title: 'Activities' }]}
        btnText='New Sub Activity'
      />
      {loading ? (
        <Loading isLoading={loading} />
      ) : error ? (
        error
      ) : (
        <div className='card shadow mb-4'>
          {data && <Table columns={columns} data={data ?? []} />}
        </div>
      )}
    </>
  );
}
