import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllData } from '../../redux/actions/api/GetAllData';
import { deleteData } from '../../redux/actions/api/DeleteData';
import { IconStar } from '@tabler/icons';
import { isArray } from 'lodash';
import Loading from '../../components/loading/Loading';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import ModalConfirm from '../../components/modal/ModalConfirm';

export default function Customers() {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state?.auth);
  const {
    data: customers,
    loading,
    error,
  } = useSelector((state) => state?.api);
  const [imageUrl, setImageUrl] = useState();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    dispatch(getAllData('customers', accessToken));
  }, [accessToken, dispatch, isShowLoading]);
  return (
    <>
      <ModalConfirm
        delete={() =>
          dispatch(
            deleteData(
              `customers`,
              accessToken,
              setShowModalConfirm,
              setIsShowLoading,
              isShowLoading,
              imageUrl
            )
          )
        }
        showModalConfirmTry={setShowModalConfirm}
        toggleModal={showModalConfirm}
      />
      <Breadcrumb
        url='/customers/new'
        title='All customers'
        textActive='Customers'
        btnText='New Customer'
      />
      {loading ? (
        <Loading isLoading={loading} />
      ) : error ? (
        error
      ) : (
        <div className='card shadow mb-4 col-9 m-auto'>
          <div className='ribbon ribbon-top bg-yellow'>
            <IconStar />
          </div>
          <div className='row p-3 justify-content-center'>
            {isArray(customers) ? (
              customers?.map((customer, index) => (
                <div className='col-3 mb-3' key={index}>
                  <img
                    style={{ cursor: 'pointer' }}
                    width='100'
                    height='100'
                    className='rounded-circle border p-2'
                    src={customer}
                    alt='images customer'
                    onClick={() => {
                      setImageUrl(customer);
                      setShowModalConfirm(true);
                    }}
                  />
                </div>
              ))
            ) : (
              <div className='col-md-12 text-error text-center'>
                You have not add any customers
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
