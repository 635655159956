import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IconStar, IconPlus } from '@tabler/icons';
import { getAllData } from '../../redux/actions/api/GetAllData';
import { deleteData } from '../../redux/actions/api/DeleteData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';
import ModalConfirm from '../../components/modal/ModalConfirm';

export default function Hero() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state?.auth);
  const { data: hero, loading, error } = useSelector((state) => state?.api);
  const [imageUrl, setImageUrl] = useState();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  useEffect(() => {
    dispatch(getAllData('hero', accessToken));
  }, [accessToken, dispatch, isShowLoading]);
  return (
    <>
      <Breadcrumb
        url='/hero/edit/text'
        title='All information about hero'
        textActive='Hero'
        btnText='Edit Hero'
      />
      <ModalConfirm
        delete={() =>
          dispatch(
            deleteData(
              `hero-images`,
              accessToken,
              setShowModalConfirm,
              setIsShowLoading,
              isShowLoading,
              imageUrl
            )
          )
        }
        showModalConfirmTry={setShowModalConfirm}
        toggleModal={showModalConfirm}
      />
      {loading ? (
        <Loading isLoading={loading} />
      ) : error ? (
        error
      ) : (
        <div className='container-fluid'>
          <div className='row col-11 col-md-8 m-auto details-info card pe-0'>
            <div className='ribbon ribbon-top bg-yellow'>
              <IconStar />
            </div>
            <div className='row px-0'>
              <div className='col-md-12 py-4 border-bottom border-start border-end'>
                <div className='row px-0'>
                  <div className='col-md-4'>
                    <h3 className='title'>hero ar :</h3>
                  </div>
                  <div className='col-md-8'>
                    <h4 className='sub-title text-end'>{hero?.hero_ar}</h4>
                  </div>
                </div>
              </div>
              <div className='col-md-12 pt-4 border-start border-end border-bottom '>
                <div className='row'>
                  <div className='col-md-4'>
                    <h3 className='title'>hero en :</h3>
                  </div>
                  <div className='col-md-8'>
                    <h4 className='sub-title'>{hero?.hero_en}</h4>
                  </div>
                </div>
              </div>
              <div className='col-md-12 pt-4 pb-2 text-center border-start border-end border-bottom'>
                {hero?.hero_images?.map((image, index) => (
                  <img
                    key={index}
                    style={{ cursor: 'pointer' }}
                    className='rounded-circle rounded mb-3 m-1'
                    width='100'
                    height='100'
                    src={image}
                    alt={hero?.hero_en}
                    onClick={() => {
                      setImageUrl(image);
                      setShowModalConfirm(true);
                    }}
                  />
                ))}
              </div>
              <div className='col-md-12 mt-3 text-center'>
                <button
                  className='btn btn-primary btn-user btn-inline'
                  onClick={() => navigate('/hero/new/image')}
                >
                  <IconPlus style={{ marginRight: '.5rem' }} />
                  New Image
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
