import React from 'react';
import { useLocation, useParams } from 'react-router';
import moment from 'moment';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';

export default function DetailsSubActivity() {
  const location = useLocation();
  const { id: activityId } = useParams();
  const {
    id: activity_id,
    image,
    description_ar,
    description_en,
    created_at,
    updated_at,
  } = location?.state;
  return (
    <>
      <Breadcrumb
        title='Details a sub activity'
        textActive='Details'
        items={[{ title: 'Activities' }, { title: 'sub-activity' }]}
      />
      {Object.values(location?.state).length > 0 ? (
        <div className='container-fluid'>
          <div className='row col-11 col-md-10 m-auto details-info'>
            <div className='row col-md-12'>
              {image?.map((image, index) => (
                <div className='col-3 m-auto text-center' key={index}>
                  <img
                    className='rounded-circle rounded mb-3 m-1'
                    width='100'
                    height='100'
                    src={image}
                    alt='sub activity'
                  />
                </div>
              ))}
            </div>
            <div className='col-md-6'>
              <div className='row border-bottom border-end border-start'>
                <div className='col-md-4'>
                  <h3 className='title'>Activity ID :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>#{activityId}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='row border-bottom border-end border-start'>
                <div className='col-md-4'>
                  <h3 className='title'>Sub Activity ID :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>#{activity_id}</h4>
                </div>
              </div>
            </div>

            <div className='col-md-12 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>description ar :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title text-end'>{description_ar}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-12 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>description en :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>{description_en}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>created at :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>
                    {moment(created_at).format('LLL')}{' '}
                  </h4>
                </div>
              </div>
            </div>
            <div className='col-md-6 pt-4 border-bottom border-end border-start border-end-mb-0'>
              <div className='row'>
                <div className='col-md-4'>
                  <h3 className='title'>updated at :</h3>
                </div>
                <div className='col-md-8'>
                  <h4 className='sub-title'>
                    {moment(updated_at).format('LLL')}{' '}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-error text-center'>
          You have not add any data equals this id sub activities
        </div>
      )}
    </>
  );
}
