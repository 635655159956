import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { editData } from '../../redux/actions/api/EditData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';

export default function EditHeroText() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);
  const { data: hero, loading, error } = useSelector((state) => state.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    hero: {
      ar: hero?.hero_ar,
      en: hero?.hero_en,
    },
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    hero: Yup.object().shape({
      ar: Yup.string()
        .required(`hero ar required`)
        .min(3, `The hero ar you should not be less than 3 characters`)
        .max(1000, `The hero ar you must not than 1000 characters`),
      en: Yup.string()
        .required(`hero en required`)
        .min(3, `The hero en you should not be less than 3 characters`)
        .max(1000, `The hero en you must not than 1000 characters`),
    }),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to modify hero
    const data = {
      hero_ar: values.hero.ar,
      hero_en: values.hero.en,
    };
    dispatch(editData('hero-text', accessToken, data, navigate, '/hero'));
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
      <Loading isLoading={loading} />
      <Breadcrumb
        title='Modify hero'
        textActive='Details'
        items={[{ title: 'hero' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='hero_ar' className='text-muted'>
                  Enter your hero ar <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user text-end'
                  name='hero.ar'
                  {...formik.getFieldProps('hero.ar')}
                  placeholder='......اكتب الوصف هنا '
                  id='hero_ar'
                  cols='30'
                  inputMode='text'
                  rows='5'
                ></textarea>
                {formik.touched.hero?.ar && formik.errors.hero?.ar ? (
                  <div className='text-error'>{formik.errors.hero?.ar}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='hero_en' className='text-muted'>
                  Enter your hero en <span className='required'>*</span>
                </label>
                <textarea
                  className='form-control form-control-user'
                  name='hero.en'
                  placeholder='write hero here....'
                  id='hero_en'
                  inputMode='text'
                  {...formik.getFieldProps('hero.en')}
                  cols='30'
                  rows='5'
                ></textarea>
                {formik.touched.hero?.en && formik.errors.hero?.en ? (
                  <div className='text-error'>{formik.errors.hero?.en}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-12 mt-3 text-center'>
              <button
                className='btn btn-primary btn-user btn-inline'
                type='submit'
              >
                <FaLocationArrow style={{ marginRight: '.5rem' }} /> Edit Hero
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
