// check input phone is not content character is content only number
export const checkValidationPhone = (e) => {
  return (e.target.value = e.target.value.replace(/[^0-9]/g, ''));
};
// ----------------------------------------------------------------------------------->
// validation file size
export function checkIfFilesAreTooBig(file) {
  let valid = true;
  if (file) {
    const size = file.size / Math.pow(1024, 2);
    if (size > 2) {
      valid = false;
    }
  }
  return valid;
}
// ----------------------------------------------------------------------------------->
// validation file type
export function checkIfFilesAreCorrectType(file) {
  let valid = true;
  if (file) {
    if (!['image/jpeg', 'image/png', 'image/svg+xml'].includes(file.type)) {
      valid = false;
    }
  }
  return valid;
}