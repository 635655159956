import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';
import { editData } from '../../redux/actions/api/EditData';

export default function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { accessToken } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    password: '',
    oldPassword: '',
    passwordConfirm: '',
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    oldPassword: Yup.string()
      .required(`old password required`)
      .min(6, `The old password you should not be less than 6 characters`)
      .max(30, `The old password you must not than 30 characters`),
    password: Yup.string()
      .required(`password required`)
      .min(6, `The password you should not be less than 6 characters`)
      .max(30, `The password you must not than 30 characters`),
    passwordConfirm: Yup.string()
      .required(`password confirm required`)
      .min(6, `The password confirm you should not be less than 6 characters`)
      .max(30, `The password confirm you must not than 30 characters`)
      .oneOf(
        [Yup.ref('password'), null],
        'password confirm do not match equals'
      ),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to modify admin
    const data = {
      old_password: values?.oldPassword,
      password: values?.password,
      password_confirmation: values?.passwordConfirm,
    };
    dispatch(
      editData(
        `admins/${id}/update-password`,
        accessToken,
        data,
        navigate,
        '/admins'
      )
    );
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
      <Loading isLoading={loading} />

      <Breadcrumb
        title='Change password admin'
        textActive='Change Password'
        items={[{ title: 'Admins' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='oldPassword' className='text-muted'>
                  Enter your old password <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='oldPassword'
                  id='oldPassword'
                  type='password'
                  inputMode='text'
                  placeholder='**************'
                  {...formik.getFieldProps('oldPassword')}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword ? (
                  <div className='text-error'>{formik.errors.oldPassword}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='password' className='text-muted'>
                  Enter your new password <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='password'
                  id='password'
                  inputMode='text'
                  type='password'
                  placeholder='**************'
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className='text-error'>{formik.errors.password}</div>
                ) : null}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor='passwordConfirm' className='text-muted'>
                  Enter your confirm new password{' '}
                  <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  name='passwordConfirm'
                  id='passwordConfirm'
                  type='password'
                  placeholder='************'
                  {...formik.getFieldProps('passwordConfirm')}
                />
                {formik.touched.passwordConfirm &&
                formik.errors.passwordConfirm ? (
                  <div className='text-error'>
                    {formik.errors.passwordConfirm}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='col-md-12 mt-3 text-center'>
              <button
                className='btn btn-primary btn-user btn-inline'
                type='submit'
              >
                <FaLocationArrow style={{ marginRight: '.5rem' }} /> Change
                Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
