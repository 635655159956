import { toast } from 'react-toastify';
import ApiConfig from '../../../api/ApiConfig';
import { DELETE_DATA, ERROR, PRE_REQUEST, REMOVE_COOKIE } from '../../types';

// delete image form api
export const deleteImage =
  (
    url,
    accessToken,
    setShowModalConfirm,
    setIsShowLoading,
    isShowLoading,
    imageUrl,
    allImage,
    setAllImage
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PRE_REQUEST,
      });
      const data = {
        image: imageUrl,
      };
      const res = await ApiConfig.put(`${url}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (res.status === 200) {
        toast.success('deleted image successfully');
        dispatch({
          type: DELETE_DATA,
        });
        setAllImage(allImage.filter((img) => img !== imageUrl));
        setShowModalConfirm(false);
        setIsShowLoading(!isShowLoading);
      }
    } catch (err) {
      setShowModalConfirm(false);
      setIsShowLoading(!isShowLoading);
      if (err?.response?.status === 401) {
        // status failed userUnauthorized token
        dispatch({ type: REMOVE_COOKIE });
        dispatch({
          type: ERROR,
          payload: '',
        });
      } else if (err?.response?.status === 422) {
        Object.entries(err?.response?.data?.msg).forEach(([key, value]) => {
          dispatch({
            type: ERROR,
            payload: value[0],
          });
        });
      } else if (err?.response?.status === 500) {
        toast.error('server error');
        dispatch({
          type: ERROR,
          payload: '',
        });
      } else {
        dispatch({
          type: ERROR,
          payload: '',
        });
        toast.error(
          'There is a problem that the remove item was not successful, please try again'
        );
      }
    }
  };
