import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  IconBrandTabler,
  IconWind,
  IconTruckDelivery,
  IconUsers,
  IconInfoSquare,
  IconLayoutBottombar,
  IconBrandAsana,
  IconBuildingSkyscraper,
  Icon3dCubeSphere,
  IconDrone
} from '@tabler/icons';

export default function Sidebar() {
  const { role } = useSelector((state) => state.auth);

  return (
    <aside className='navbar navbar-vertical navbar-expand-lg navbar-transparent bg-white'>
      <div className='container-fluid'>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbar-menu'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <h1 className='navbar-brand navbar-brand-autodark'>
          <Link to='/'>
            <IconBrandTabler
              style={{ height: '3rem', width: '3rem' }}
              color='green'
            />
          </Link>
        </h1>
        <div className='collapse navbar-collapse' id='navbar-menu'>
          <ul className='navbar-nav pt-lg-3'>
            <li className='nav-item'>
              <Link className='nav-link' to='/hero'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconWind color='#4263eb' />
                </span>
                <span className='nav-link-title'>Hero</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/activities '>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconTruckDelivery color='#4263eb' />
                </span>
                <span className='nav-link-title'>Activities </span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/principles'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <Icon3dCubeSphere color='#4263eb' />
                </span>
                <span className='nav-link-title'>Principles</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/customers'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconBuildingSkyscraper color='#4263eb' />
                </span>
                <span className='nav-link-title'>Customers</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/partners'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconDrone color='#4263eb' />
                </span>
                <span className='nav-link-title'>Partners</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/projects'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconBrandAsana color='#4263eb' />
                </span>
                <span className='nav-link-title'>Projects</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/about-us'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconInfoSquare color='#4263eb' />
                </span>
                <span className='nav-link-title'>About us</span>
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/footer'>
                <span className='nav-link-icon d-md-none d-lg-inline-block'>
                  <IconLayoutBottombar color='#4263eb' />
                </span>
                <span className='nav-link-title'>Footer</span>
              </Link>
            </li>
            {role === 'super' && (
              <li className='nav-item'>
                <Link className='nav-link' to='/admins'>
                  <span className='nav-link-icon d-md-none d-lg-inline-block'>
                    <IconUsers color='#4263eb' />
                  </span>
                  <span className='nav-link-title'>Admins</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </aside>
  );
}
