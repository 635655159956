import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { LOIN_REQUEST_SUCCESS, REMOVE_COOKIE } from '../../../redux/types';
import ApiConfig from '../../../api/ApiConfig';
import Loading from '../../../components/loading/Loading';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch({ type: REMOVE_COOKIE });
  }, [dispatch]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    email: '',
    password: '',
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    email: Yup.string()
      .required(`email required`)
      .min(6, `The email you should not be less than 6 characters`)
      .max(40, `The email you must not than 40 characters`)
      .email('Invalid email format'),
    password: Yup.string()
      .required(`password required`)
      .min(6, `The password you should not be less than 6 characters`)
      .max(20, `The password you must not than 20 characters`),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to login as dashboard
    setIsLoading(true);
    const data = {
      email: values.email,
      password: values.password,
    };
    ApiConfig.post(`auth/login`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          toast.success('Successful logged in to control panel');
          const { role, access_token, name } = res?.data?.data;
          //save data as cookie storage
          dispatch({
            type: LOIN_REQUEST_SUCCESS,
            payload: {
              accessToken: access_token,
              refreshToken: '',
              role: role,
              email: values.email,
              username: name,
            },
          });
          if (role === 'super' || role === 'admin') {
            // check is super admin or main
            navigate('/hero');
          } else {
            toast.warn(
              "can't access panel role is not admin a, please try again"
            );
          }
        } else {
          toast.error(
            'There is a problem that the login was not successful, please try again'
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.status === 422) {
          Object.entries(err?.response?.data?.msg).forEach(([key, value]) => {
            toast.error(value[0]);
          });
        } else {
          toast.error('error email or password please try again');
        }
      });
  };
  // ----------------------------------------------------------------------------------->
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <>
      <Loading isLoading={isLoading} />
      <div className='page page-center'>
        <div className='container-tight py-4'>
          <div className='text-center'>
            <Link to='/' className='navbar-brand navbar-brand-autodark'>
              <img
                className='rounded-circle bg-white p-1'
                height='120'
                src='./assets/images/logo.png'
                alt='logo abar'
              />
            </Link>
          </div>
          <form
            className='card card-md'
            autoComplete='off'
            onSubmit={formik.handleSubmit}
          >
            <div className='card-body'>
              <h2 className='card-title text-center mb-4'>
                Login to your account
              </h2>
              <div className='mb-3'>
                <label className='form-label' htmlFor='email'>
                  Email address
                </label>
                <input
                  type='email'
                  name='email'
                  inputMode='email'
                  id='email'
                  className='form-control'
                  placeholder='example@gmail.com'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className='text-error'>{formik.errors.email}</div>
                ) : null}
              </div>
              <div className='mb-2'>
                <label className='form-label' htmlFor='password'>
                  Password
                </label>
                <div className='input-group input-group-flat'>
                  <input
                    type='password'
                    name='password'
                    id='password'
                    inputMode='text'
                    className='form-control'
                    placeholder='***************'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className='text-error'>{formik.errors.password}</div>
                  ) : null}
                  <span className='input-group-text'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='icon'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      strokeWidth='2'
                      stroke='currentColor'
                      fill='none'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                      <circle cx='12' cy='12' r='2'></circle>
                      <path d='M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7'></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div className='mb-2'>
                <label className='form-check'>
                  <input type='checkbox' className='form-check-input' />
                  <span className='form-check-label'>
                    Remember me on this device
                  </span>
                </label>
              </div>
              <div className='form-footer'>
                <button type='submit' className='btn btn-primary w-100'>
                  Sign in
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
