import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage, FieldArray } from 'formik';
import { MdAdd, MdRemove } from 'react-icons/md';
import { FaLocationArrow } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { editData } from '../../../redux/actions/api/EditData';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import Loading from '../../../components/loading/Loading';
import TextErrorMessage from '../../../components/message/TextErrorMessage';
import {
  checkIfFilesAreCorrectType,
  checkIfFilesAreTooBig,
} from '../../../utils/function';
import ModalConfirm from '../../../components/modal/ModalConfirm';
import { deleteImage } from '../../../redux/actions/api/deleteImage';

export default function EditSubActivity() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, subId } = useParams();
  const [imageUrl, setImageUrl] = useState();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const { accessToken } = useSelector((state) => state.auth);
  const activity = useSelector((state) =>
    state.api.data.find((activity) => activity.id.toString() === subId)
  );
  const [allImage, setAllImage] = useState(activity?.image);
  const { loading, error } = useSelector((state) => state?.api);
  // ----------------------------------------------------------------------------------->
  useEffect(() => {
    error && toast.error(error);
  }, [dispatch, error, loading]);
  // ----------------------------------------------------------------------------------->
  const initialValues = {
    // initial Values input felids
    images: [{ image: '' }],
    description: {
      ar: activity?.description_ar,
      en: activity?.description_en,
    },
  };
  // ----------------------------------------------------------------------------------->
  const validationSchema = Yup.object({
    // validation and check input is not empty
    description: Yup.object().shape({
      ar: Yup.string()
        .required(`description ar required`)
        .min(3, `The description ar you should not be less than 3 characters`)
        .max(1000, `The description ar you must not than 1000 characters`),
      en: Yup.string()
        .required(`description en required`)
        .min(3, `The description en you should not be less than 3 characters`)
        .max(1000, `The description en you must not than 1000 characters`),
    }),
    images: Yup.array().of(
      Yup.object().shape({
        image: Yup.mixed()
          .test(
            'fileSize',
            'File size too large, max file size is 2 Mb',
            (value) => !value || checkIfFilesAreTooBig(value)
          )
          .test(
            'fileFormat',
            'Uploaded file has unsupported format.',
            (value) => !value || checkIfFilesAreCorrectType(value)
          ),
      })
    ),
  });
  // ----------------------------------------------------------------------------------->
  const onSubmit = (values) => {
    // handel to edit activity
    var data = new FormData();
    for (let i = 0; i < values?.images.length; i++) {
      data.append(`images[${i}]`, values?.images[i].image || []);
    }
    data.append('service_id', id);
    data.append('description_en', values?.description?.en);
    data.append('description_ar', values?.description?.ar);
    data.append('_method', 'PUT');
    dispatch(
      editData(
        `/sub-services/${subId}`,
        accessToken,
        data,
        navigate,
        `/activities/${id}/sub-activities`,
        true
      )
    );
  };
  return (
    <>
      <ModalConfirm
        delete={() =>
          dispatch(
            deleteImage(
              `sub-services-image/${subId}`,
              accessToken,
              setShowModalConfirm,
              setIsShowLoading,
              isShowLoading,
              imageUrl,
              allImage,
              setAllImage
            )
          )
        }
        showModalConfirmTry={setShowModalConfirm}
        toggleModal={showModalConfirm}
      />
      <Loading isLoading={loading} />
      <Breadcrumb
        title='Edit a sub activity'
        textActive='Edit'
        items={[{ title: 'Activities' }, { title: 'sub-activity' }]}
      />
      <div className='card rounded-1 mb-4 p-3'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <FieldArray name='images'>
                  {(fieldArrayProps) => {
                    const { push, remove, form } = fieldArrayProps;
                    const { values } = form;
                    const { images } = values;
                    return (
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label
                              htmlFor='description_ar'
                              className='text-gray-600 small'
                            >
                              Enter your description ar{' '}
                              <span className='required'>*</span>
                            </label>
                            <textarea
                              className='form-control form-control-user text-end'
                              name='description.ar'
                              {...formik.getFieldProps('description.ar')}
                              id='description_ar'
                              inputMode='text'
                              cols='30'
                              rows='5'
                            ></textarea>
                            {formik.touched.description?.ar &&
                            formik.errors.description?.ar ? (
                              <div className='text-error'>
                                {formik.errors.description?.ar}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label
                              htmlFor='description_en'
                              className='text-gray-600 small'
                            >
                              Enter your description en{' '}
                              <span className='required'>*</span>
                            </label>
                            <textarea
                              className='form-control form-control-user'
                              name='description.en'
                              id='description_en'
                              inputMode='text'
                              {...formik.getFieldProps('description.en')}
                              cols='30'
                              rows='5'
                            ></textarea>
                            {formik.touched.description?.en &&
                            formik.errors.description?.en ? (
                              <div className='text-error'>
                                {formik.errors.description?.en}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className='row position-relative'>
                          <div className='col-md-7'>
                            <div className='form-group'>
                              <label htmlFor='image' className='text-muted'>
                                Enter your image{' '}
                                <span className='required'>*</span>
                              </label>
                            </div>
                            {images?.map((image, index) => (
                              <div className='row mb-1' key={index}>
                                <div className='col-md-11'>
                                  <div className='form-group'>
                                    <input
                                      className='form-control'
                                      name={`images.${index}.image`}
                                      accept='image/*'
                                      id='image'
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          `images.${index}.image`,
                                          e.target.files[0]
                                        )
                                      }
                                      type='file'
                                    />
                                    <ErrorMessage
                                      name={`images[${index}].image`}
                                      component={TextErrorMessage}
                                    />
                                  </div>
                                </div>
                                {index > 0 && (
                                  <div className='col-md'>
                                    <div className='form-group'>
                                      <button
                                        type='button'
                                        className='remove-item btn btn-danger mt-2'
                                        onClick={() => remove(index)}
                                      >
                                        <MdRemove />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>

                          <div className='col-md'>
                            <div className='form-group'>
                              <button
                                style={{ bottom: '4px' }}
                                type='button'
                                className='add-item btn btn-success'
                                onClick={() => push('')}
                              >
                                <MdAdd />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='row mt-3'>
                          {allImage?.map((image) => (
                            <div className='col-2' key={image}>
                              <img
                                className='rounded-circle'
                                style={{ cursor: 'pointer' }}
                                src={image}
                                width={100}
                                onClick={() => {
                                  setImageUrl(image);
                                  setShowModalConfirm(true);
                                }}
                                height={100}
                                alt='images'
                              />
                            </div>
                          ))}
                        </div>
                        <div className='col-md-12 mt-3 text-center'>
                          <button
                            className='btn btn-primary btn-user btn-inline'
                            type='submit'
                          >
                            <FaLocationArrow style={{ marginRight: '.5rem' }} />{' '}
                            Edit Sub Activity
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </FieldArray>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
