import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconStar } from '@tabler/icons';
import { getAllData } from '../../redux/actions/api/GetAllData';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Loading from '../../components/loading/Loading';

export default function AboutUs() {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state?.auth);
  const { data: aboutUs, loading, error } = useSelector((state) => state?.api);
  useEffect(() => {
    dispatch(getAllData('about', accessToken));
  }, [accessToken, dispatch]);
  return (
    <>
      <Breadcrumb
        url='/about-us/edit'
        title='All information about us'
        textActive='About us'
        btnText='Edit About Us'
      />
      {loading ? (
        <Loading isLoading={loading} />
      ) : error ? (
        error
      ) : (
        <div className='container-fluid'>
          <div className='row col-11 col-md-8 m-auto details-info card pe-0'>
            <div className='ribbon ribbon-top bg-yellow'>
              <IconStar />
            </div>
            <div className='row px-0'>
              <div className='col-md-12 py-4 border-bottom border-end border-bottom border-start'>
                <div className='row'>
                  <div className='col-md-4'>
                    <h3 className='title'>About ar :</h3>
                  </div>
                  <div className='col-md-8'>
                    <h4 className='sub-title text-end'>{aboutUs?.about_ar}</h4>
                  </div>
                </div>
              </div>
              <div className='col-md-12 pt-4 border-end border-bottom border-start'>
                <div className='row'>
                  <div className='col-md-4'>
                    <h3 className='title'>About en :</h3>
                  </div>
                  <div className='col-md-8'>
                    <h4 className='sub-title'>{aboutUs?.about_en}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
