import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PrivateWrapper from './auth/PrivateRoute';
import PublicRoute from './auth/PublicRoute';
import SuperRoute from './auth/SuperRoute';
import AboutUs from './pages/about-us/AboutUs';
import EditAboutUs from './pages/about-us/Edit';
import Activities from './pages/activity/Activities';
import DetailsActivity from './pages/activity/Details';
import EditActivity from './pages/activity/Edit';
import NewActivity from './pages/activity/New';
import DetailsSubActivity from './pages/activity/sub/Details';
import EditSubActivity from './pages/activity/sub/Edit';
import NewSubActivity from './pages/activity/sub/New';
import SubActivity from './pages/activity/sub/Sub';
import Admins from './pages/admin/Admins';
import ChangePassword from './pages/admin/ChangePassword';
import EditAdmin from './pages/admin/Edit';
import NewAdmin from './pages/admin/New';
import Login from './pages/auth/login/Login';
import Customers from './pages/customer/Customers';
import NewCustomer from './pages/customer/New';
import EditFooter from './pages/footer/Edit';
import Footer from './pages/footer/Footer';
import EditHeroText from './pages/hero/EditText';
import Hero from './pages/hero/Hero';
import NewImageHero from './pages/hero/NewImage';
import NewPartners from './pages/partners/New';
import Partners from './pages/partners/Partners';
import DetailsPrinciple from './pages/principle/Details';
import EditPrinciples from './pages/principle/Edit';
import NewPrinciples from './pages/principle/New';
import Principles from './pages/principle/Principles';
import Profile from './pages/profile/Profile';
import NewProject from './pages/project/New';
import Projects from './pages/project/Projects';

function App() {
  const { isLogin,role } = useSelector((state) => state.auth);
  return (
    <div className='page'>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <PublicRoute isLogin={isLogin}>
                <Login path='/' />
              </PublicRoute>
            }
          />
          {/* route is role super */}
           <Route element={<SuperRoute isLogin={isLogin} role={role} />}>
            <Route path='/admins' element={<Admins />} />
            <Route path='/admins/new' element={<NewAdmin />} />
            <Route path='/admins/:id/edit' element={<EditAdmin />} />
            <Route path='/admins/:id/password' element={<ChangePassword />} />
           </Route>
           {/* route is role admin or super */}
          <Route element={<PrivateWrapper isLogin={isLogin} />}>
            <Route path='/hero' element={<Hero />} />
            <Route path='/hero/edit/text' element={<EditHeroText />} />
            <Route path='/hero/new/image' element={<NewImageHero />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/footer' element={<Footer />} />
            <Route path='/footer/edit' element={<EditFooter />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/about-us/edit' element={<EditAboutUs />} />
            <Route path='/principles' element={<Principles />} />
            <Route path='/principles/new' element={<NewPrinciples />} />
            <Route path='/principles/:id/edit' element={<EditPrinciples />} />
            <Route path='/principles/:id/details' element={<DetailsPrinciple />} />
            <Route path='/customers' element={<Customers />} />
            <Route path='/customers/new' element={<NewCustomer />} />
            <Route path='/partners' element={<Partners />} />
            <Route path='/partners/new' element={<NewPartners />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/projects/new' element={<NewProject />} />
            <Route path='/activities' element={<Activities />} />
            <Route path='/activities/new' element={<NewActivity />} />
            <Route path='/activities/:id/edit' element={<EditActivity />} />
            <Route path='/activities/:id/details' element={<DetailsActivity />} />
            <Route path='/activities/:id/sub-activities' element={<SubActivity />} />
            <Route path='/activities/:id/sub-activities/new' element={<NewSubActivity />} />
            <Route path='/activities/:id/sub-activities/:subId/details' element={<DetailsSubActivity />} />
            <Route path='/activities/:id/sub-activities/:subId/edit' element={<EditSubActivity />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
